import React, { useEffect, useContext } from "react";
import { Page, Box, Heading } from "grommet";
import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import { isBrowser } from "../../hooks/auth";
const Contact = ({ location }) => {
  if (!isBrowser) return;

  const dispatch = useContext(GlobalDispatchContext);

  useEffect(() => {
    dispatch({
      type: "TOGGLE_CONTACT",
      payload: {
        contactLayer: true,
      },
    });
    navigate("/");
  }, []);
  return <></>;
};

export function Head() {
  return <title>Contact Us | BIM Engineering & Construction</title>;
}

export default Contact;
